import makeApiRequest from "../common/makeApiRequest";
import {
  Lang,
  PageData,
  Post,
  PostForm,
  PostType,
  Status
} from "../common/models";
import { buildQueryParams, validateResponse } from "../common/utils";

export interface PostQuery {
  type?: PostType;
  lang?: Lang;
  status?: Status;
  page?: number;
}

export async function savePost(value: PostForm) {
  const form = new FormData();
  value.id && form.append("id", `${value.id}`);
  value.title && form.append("title", value.title);
  value.slug && form.append("slug", value.slug);
  value.cover && form.append("cover", value.cover);
  value.status && form.append("status", value.status);
  value.type && form.append("type", value.type);
  value.lang && form.append("lang", value.lang);
  value.author?.id && form.append("authorId", `${value.author.id}`);
  value.description && form.append("description", value.description);
  value.body && form.append("body", value.body);
  value.file && form.append("file", value.file);
  form.append("notify", value.notify ? "true" : "false");
  value.scheduleDateTime &&
    form.append("scheduleDateTime", `${value.scheduleDateTime}`);

  value.tags?.forEach((t, i) => {
    t.id && form.append(`tagList[${i}]`, `${t.id}`);
  });

  const url = `v1/admin/posts`;

  const resp = await makeApiRequest(
    url,
    {
      method: "POST",
      body: form
    },
    true
  );

  await validateResponse(resp);

  return (await resp.json()) as Post;
}

export async function deletePostById(id: number) {
  const url = `v1/admin/posts/${id}`;

  const resp = await makeApiRequest(
    url,
    {
      method: "DELETE"
    },
    true
  );

  await validateResponse(resp);
}

export async function getPostById(id: number) {
  const url = `v1/admin/posts/${id}`;

  const resp = await makeApiRequest(url, {}, true);

  await validateResponse(resp);

  return resp
    .json()
    .then((json) => json as Post)
    .catch((e) => undefined);
}

export async function findPosts(query: PostQuery) {
  const params = buildQueryParams({ ...query });
  const url = `v1/admin/posts${params}`;

  const resp = await makeApiRequest(url, {}, true);

  await validateResponse(resp);

  return resp.json() as Promise<PageData<Post>>;
}
