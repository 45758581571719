import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Tag } from "../common/models";
import {
  parseErrorResponse,
  setEmptyOrString,
  setStringToSlug
} from "../common/utils";
import { Input } from "../components/forms";
import Modal from "../components/Modal";
import ProgressButton from "../components/ProgressButton";
import { saveTag } from "./TagAPI";

interface TagEditProps {
  value?: Tag;
  show: boolean;
  close?: (result?: boolean) => void;
  onHidden?: () => void;
}

function TagEdit({ value = {}, show, close, onHidden }: TagEditProps) {
  const {
    control,
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({ defaultValues: {}, values: value });

  return (
    <Modal
      show={show}
      onHidden={() => {
        onHidden?.();
        reset({});
      }}
    >
      {(isShown) => {
        if (!isShown) {
          return <></>;
        }

        return (
          <>
            <div className="modal-header">
              <h4 className="modal-title">Edit Tag</h4>
              <button
                type="button"
                className="btn-close shadow-none"
                aria-label="Close"
                onClick={() => {
                  close?.();
                  reset();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-3">
                <div className="col-12">
                  <Input
                    label="Name"
                    {...register("name", {
                      required: "Please enter tag name",
                      setValueAs: setEmptyOrString,
                      onChange: (evt) => {
                        setValue("slug", setStringToSlug(evt.target.value), {
                          shouldValidate: !!errors.slug?.message
                        });
                      }
                    })}
                    error={errors.name?.message}
                  />
                </div>
                <div className="col-12">
                  <Controller
                    control={control}
                    name="slug"
                    rules={{
                      validate: (v) => {
                        if (!setStringToSlug(v)) {
                          return "Please enter valid slug";
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <Input
                            label="Slug"
                            value={field.value ?? ""}
                            onChange={(evt) => {
                              const s = evt.target.value
                                .replace(/[^\w-\s]*/, "")
                                .replace(/\s+/, "-")
                                .toLowerCase();

                              setValue("slug", s, {
                                shouldValidate: true
                              });
                            }}
                            error={error?.message}
                          />
                          {!error?.message && (
                            <small className="text-muted">{`${
                              process.env.REACT_APP_CONSUMER_URL
                            }/tags/${field.value ?? ""}`}</small>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <ProgressButton
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit(async (data) => {
                    try {
                      await saveTag(data);
                      toast.success(
                        `Tag successfully ${!data.id ? "created" : "updated"}`
                      );
                      close?.(true);
                    } catch (error) {
                      const msg = parseErrorResponse(error);
                      toast.error(msg);
                    }
                  })();
                }}
              >
                Save
              </ProgressButton>
            </div>
          </>
        );
      }}
    </Modal>
  );
}

export default TagEdit;
