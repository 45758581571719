import { Link, useRouteError } from "react-router-dom";

function ErrorPage() {
  const error = useRouteError() as any;
  //console.log(error);
  let returnLink = (
    <Link to="/" className="text-blue-600 underline font-medium">
      Return to home
    </Link>
  );

  // if (error?.status === 403) {
  //   returnLink = <div></div>;
  // }

  // if (error?.status === 401) {
  //   return null;
  // }

  return (
    <div className="vstack py-4 justify-content-center align-items-center mt-8">
      <h1 className="text-4xl text-gray-800 whitespace-nowrap">
        <span>{error?.status}</span>
        <span className="ms-2">{error?.statusText}</span>
      </h1>
      {returnLink}
    </div>
  );
}

export default ErrorPage;
