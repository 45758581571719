import makeApiRequest from "../common/makeApiRequest";
import { Author, PageData } from "../common/models";
import { buildQueryParams, validateResponse } from "../common/utils";

export interface AuthorQuery {
  name?: string;
  page?: number;
}

export async function saveAuthor(value: Author) {
  const form = new FormData();
  value.id && form.append("id", `${value.id}`);
  value.name && form.append("name", value.name);
  value.slug && form.append("slug", value.slug);
  value.gender && form.append("gender", value.gender);
  value.role && form.append("role", value.role);
  value.biography && form.append("biography", value.biography);
  value.file && form.append("file", value.file);

  const url = `v1/admin/authors`;

  const resp = await makeApiRequest(
    url,
    {
      method: "POST",
      body: form
    },
    true
  );

  await validateResponse(resp);

  return (await resp.json()) as Author;
}

export async function deleteAuthorById(id: number) {
  const url = `v1/admin/authors/${id}`;

  const resp = await makeApiRequest(
    url,
    {
      method: "DELETE"
    },
    true
  );

  await validateResponse(resp);
}

export async function getAuthorById(id: number) {
  const url = `v1/admin/authors/${id}`;

  const resp = await makeApiRequest(url, {}, true);

  await validateResponse(resp);

  return (await resp.json()) as Author;
}

export async function findAuthors(query: AuthorQuery) {
  const params = buildQueryParams(query);

  const url = `v1/admin/authors${params}`;

  const resp = await makeApiRequest(url, {}, true);

  await validateResponse(resp);

  return resp.json() as Promise<PageData<Author>>;
}
