import { domAnimation, LazyMotion, m } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getLoginUser } from "../authentication/AuthenticationAPI";
import {
  AuthContext,
  AuthState,
  UpdateAuthContextFn
} from "../common/contexts";
import { UnauthorizeError } from "../common/customs";
import { parseErrorResponse } from "../common/utils";
import ErrorBoundary from "../components/ErrorBoundary";
import Alert from "./Alert";
import Footer from "./Footer";
import Header from "./Header";
import SideMenu from "./SideMenu";

const minSideMenuWidth = 80;
const maxSideMenuWidth = 250;

function Template() {
  const [menuExpanded, setMenuExpanded] = useState(true);
  const [delayExpanded, setDelayExpanded] = useState(true);

  const updateAuthState = useCallback<UpdateAuthContextFn>((status, user) => {
    setAuthState((old) => {
      return { ...old, status: status, currentUser: user };
    });
  }, []);

  const [authState, setAuthState] = useState<AuthState>({
    status: "loading",
    update: updateAuthState
  });

  const [error, setError] = useState<string>();

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;

      setMenuExpanded(width >= 992);
    };

    const { innerWidth: width } = window;
    setMenuExpanded(width >= 992);

    window.addEventListener("resize", handleResize);

    const loadUser = async () => {
      try {
        const user = await getLoginUser();
        if (!user.role?.match("ADMIN|OWNER")) {
          throw new UnauthorizeError();
        }
        setAuthState((old) => {
          return { ...old, status: "success", currentUser: user };
        });
      } catch (error) {
        if (error instanceof UnauthorizeError) {
          setAuthState((old) => {
            return { ...old, status: "unauthorized" };
          });
        } else {
          setAuthState((old) => {
            return { ...old, status: "failed" };
          });
        }
        setError(parseErrorResponse(error));
      }
    };

    loadUser();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const expanded = menuExpanded;
    const timer = setTimeout(
      () => {
        setDelayExpanded(expanded);
      },
      menuExpanded ? 10 : minSideMenuWidth
    );

    return () => {
      clearTimeout(timer);
    };
  }, [menuExpanded]);

  const content = () => {
    if (authState.status === "loading") {
      return (
        <div className="h-100 bg-gray-900">
          <div className="container h-100">
            <div className="hstack h-100">
              <div className="m-auto hstack gap-2">
                <div
                  className="spinner-grow spinner-grow-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className="spinner-grow spinner-grow-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className="spinner-grow spinner-grow-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (authState.status === "failed") {
      return <Alert message={error} varaint="danger" className="m-3" />;
    }

    if (authState.status === "unauthorized") {
      return <></>;
    }

    return (
      <div className="d-flex h-100">
        <LazyMotion features={domAnimation} strict>
          <m.div
            className="bg-secondary flex-shrink-0"
            initial={{
              width: maxSideMenuWidth
            }}
            animate={{
              width: menuExpanded ? maxSideMenuWidth : minSideMenuWidth
            }}
            transition={{
              ease: "easeIn",
              width: { duration: 0.1 }
            }}
            style={{
              minWidth: minSideMenuWidth,
              width: menuExpanded ? maxSideMenuWidth : minSideMenuWidth
              //transition: "width 0.1s ease-in"
            }}
          >
            <SideMenu expanded={delayExpanded} />
          </m.div>
        </LazyMotion>
        <div ref={contentRef} className="h-100 flex-grow-1 overflow-auto">
          <div className="d-flex flex-column h-100">
            <div className="flex-shrink-0">
              <Header
                onBarClick={() => {
                  setMenuExpanded(!menuExpanded);
                }}
              />
            </div>

            <div className="d-flex flex-column flex-grow-1 p-3">
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop
                theme="colored"
              />
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </div>

            <div className="flex-shrink-0">
              <Footer
              // scrollToTop={() => {
              //   contentRef.current?.scrollTo({
              //     top: 0,
              //     left: 0,
              //     behavior: "smooth"
              //   });
              // }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <AuthContext.Provider value={authState}>{content()}</AuthContext.Provider>
  );
}

export default Template;
