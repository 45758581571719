import { useParams } from "react-router-dom";
import useSWR from "swr";
import { getAuthorById } from "./AuthorAPI";
import AuthorEdit from "./AuthorEdit";

function AuthorUpdate() {
  const { id } = useParams();

  if (!id || isNaN(+id)) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }

  const { data, error, isLoading, isValidating } = useSWR(
    `/authors/${+id}`,
    () => getAuthorById(+id),
    {
      revalidateOnFocus: false,
      errorRetryCount: 0
    }
  );

  if (isLoading || isValidating) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  return <AuthorEdit value={data} />;
}

export default AuthorUpdate;
