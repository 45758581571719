import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Lang, PostForm, PostType } from "../common/models";
import {
  parseErrorResponse,
  setEmptyOrString,
  setStringToSlug
} from "../common/utils";
import AuthorSelect from "../components/AuthorSelect";
import ConfirmModal from "../components/ConfirmModal";
import {
  DatePickerInput2,
  Input,
  RichTextEditor,
  Select
} from "../components/forms";
import Modal from "../components/Modal";
import ProgressButton from "../components/ProgressButton";
import TagSelect from "../components/TagSelect";
import { savePost } from "./PostAPI";

function PostEdit({ post }: { post: PostForm }) {
  const navigate = useNavigate();

  const fileRef = useRef<HTMLInputElement>(null);

  const [isShowConfirmPublish, setShowConfirmPublish] = useState(false);

  const [isShowSchedulePublish, setShowSchedulePublish] = useState(false);

  const {
    control,
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue
  } = useForm({ defaultValues: {}, values: post });

  useEffect(() => {
    dayjs.extend(utc);
  }, []);

  function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
    try {
      const files = event.target.files;

      if (files && files.length > 0) {
        const file = files[0];
        const fileSize = file.size / (1024 * 1024);

        if (fileSize > 0.48) {
          throw Error("File size must not greater than 480KB");
        }

        var reader = new FileReader();
        reader.onload = function (e) {
          const result = e.target?.result;
          if (result && typeof result === "string") {
            setValue("file", file);
            setValue("cover", result);
          }
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      const msg = parseErrorResponse(error);
      toast.error(msg);
    } finally {
      event.target.value = "";
    }
  }

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col-lg-6">
          <h3 className="fw-semibold mb-1">Post Edit</h3>
          <nav aria-label="breadcrumb col-12">
            <ol className="breadcrumb mb-1">
              <li className="breadcrumb-item">
                <Link to="/posts" className="link-anchor">
                  Posts
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Post Edit
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-lg-6 hstack">
          <div className="hstack gap-2 ms-lg-auto">
            {post.status !== "PUBLISHED" && (
              <button
                disabled={isSubmitting}
                className="btn btn-default"
                onClick={() => {
                  handleSubmit((data) => {
                    setShowSchedulePublish(true);
                  })();
                }}
              >
                Schedule
              </button>
            )}
            <button
              disabled={isSubmitting}
              className="btn btn-primary"
              onClick={() => {
                handleSubmit((data) => {
                  setShowConfirmPublish(true);
                })();
              }}
            >
              {!post.id ? "Publish" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <div className="row g-3 flex-grow-1">
        <div className="col-12 col-lg-8 order-2 order-lg-1 flex-grow-1">
          <div className="card overflow-hidden h-100">
            <div className="card-body p-0">
              <Controller
                control={control}
                name="body"
                render={({ field }) => {
                  return (
                    <RichTextEditor
                      value={field.value}
                      placeholder="Enter post body..."
                      noBorder
                      iframeEmbed
                      height={"100%"}
                      onEditorChange={(value) => {
                        setValue("body", value);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 order-1 order-lg-2">
          <div className="card">
            <div className="card-body">
              <div className="row g-3">
                <div className="col-12">
                  <Input
                    label="Title"
                    {...register("title", {
                      required: "Please enter post title",
                      setValueAs: setEmptyOrString,
                      onChange: (evt) => {
                        setValue("slug", setStringToSlug(evt.target.value), {
                          shouldValidate: !!errors.slug?.message
                        });
                      }
                    })}
                    error={errors.title?.message}
                  />
                </div>

                <div className="col-12">
                  <Controller
                    control={control}
                    name="slug"
                    rules={{
                      validate: (v) => {
                        if (!setStringToSlug(v)) {
                          return "Please enter valid slug";
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <Input
                            label="Slug"
                            value={field.value ?? ""}
                            onChange={(evt) => {
                              const s = evt.target.value
                                .replace(/[^\w-\s\u1000-\u109F]*/, "")
                                .replace(/\s+/, "-")
                                .toLowerCase();

                              setValue("slug", s, {
                                shouldValidate: true
                              });
                            }}
                            error={error?.message}
                          />
                          {!error?.message && (
                            <small className="text-muted">{`${
                              process.env.REACT_APP_CONSUMER_URL
                            }/posts/${field.value ?? ""}`}</small>
                          )}
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12">
                  <Select
                    label="Type"
                    {...register("type", {
                      setValueAs: (v) => (!v ? undefined : (v as PostType))
                    })}
                  >
                    <option value="NEWS">News</option>
                    <option value="ARTICLE">Article</option>
                    <option value="VIDEO">Video</option>
                    <option value="PODCAST">Podcast</option>
                    <option value="SURVEY">Survey</option>
                    <option value="LAW">Law</option>
                  </Select>
                </div>

                <div className="col-12">
                  <Select
                    label="Lang"
                    {...register("lang", {
                      setValueAs: (v) => (!v ? undefined : (v as Lang))
                    })}
                  >
                    <option value="MM">Myanmar</option>
                    <option value="EN">English</option>
                  </Select>
                </div>

                <div className="col-12">
                  <label className="form-label">Author</label>
                  <Controller
                    control={control}
                    name="author"
                    rules={{
                      validate: (a) => {
                        if (!a) {
                          return "Please select author";
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <AuthorSelect
                            value={field.value}
                            onChange={(value) => {
                              setValue("author", value, {
                                shouldValidate: true
                              });
                            }}
                            error={error?.message}
                          />
                          {error?.message && (
                            <div className="text-danger small mt-1">
                              {error.message}
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12">
                  <label className="form-label">Tags</label>
                  <Controller
                    control={control}
                    name="tags"
                    render={({ field }) => {
                      return (
                        <TagSelect
                          value={field.value}
                          onChange={(values) => {
                            setValue("tags", values);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div className="col-12">
                  <label className="form-label">Cover</label>
                  <div className="position-relative rounded overflow-hidden">
                    <div className="ratio ratio-16x9">
                      <Controller
                        control={control}
                        name="cover"
                        render={({ field }) => {
                          return (
                            <img
                              src={field.value ?? "/images/placeholder.jpeg"}
                              alt="Cover"
                              style={{
                                objectFit: "cover"
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <div
                      role={"button"}
                      className="bg-dark bg-opacity-50 py-2 text-center text-light position-absolute bottom-0 start-0 end-0"
                      onClick={() => fileRef.current?.click()}
                    >
                      Click to choose
                    </div>
                  </div>

                  <input
                    ref={fileRef}
                    type="file"
                    className="d-none"
                    accept="image/x-png,image/jpeg"
                    onChange={handleImageChange}
                  />
                </div>

                {post.status !== "PUBLISHED" && (
                  <>
                    <div className="col-12">
                      <hr className="bg-dark-gray" />
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input
                          id="notifyCheck"
                          className="form-check-input"
                          type={"checkbox"}
                          {...register("notify")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="notifyCheck"
                        >
                          Notify publish
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={isShowConfirmPublish}
        message={`Are you sure to ${!post.id ? "publish" : "update"} post?`}
        close={() => setShowConfirmPublish(false)}
        onConfirm={async () => {
          await handleSubmit(async (data) => {
            try {
              const result = await savePost({
                ...data,
                status: "PUBLISHED",
                scheduleDateTime: undefined
              });
              toast.success(
                `Post sucessfully ${!data.id ? "published" : "updated"}`
              );
              setValue("id", result.id);
              setValue("status", result.status);
            } catch (error) {
              const msg = parseErrorResponse(error);
              toast.error(msg);
            }
          })();
        }}
      />

      <Modal show={isShowSchedulePublish}>
        {(isShown) => {
          if (!isShown) {
            return <></>;
          }

          return (
            <>
              <div className="modal-header">
                <h4 className="modal-title">Schedule date time</h4>
                <button
                  type="button"
                  className="btn-close shadow-none"
                  aria-label="Close"
                  onClick={() => {
                    setShowSchedulePublish(false);
                    setValue("scheduleDateTime", undefined);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <Controller
                  control={control}
                  name="scheduleDateTime"
                  render={({ field }) => {
                    return (
                      <>
                        <DatePickerInput2
                          placeholder="Select date time"
                          value={
                            field.value
                              ? dayjs(field.value).format(
                                  "MMM DD, YYYY hh:mm A"
                                )
                              : ""
                          }
                          dates={
                            field.value ? [dayjs(field.value).toDate()] : []
                          }
                          mode="single"
                          enableTime
                          dateFormat="M d, Y h:i K"
                          minDate={new Date()}
                          onDateChange={(values) => {
                            if (values.length === 0) {
                              return;
                            }

                            const d = values[0];

                            // console.log(
                            //   dayjs(d).utc().format("YYYY-MM-DD hh:mm A")
                            // );

                            setValue("scheduleDateTime", d.getTime());
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div>
              <div className="modal-footer">
                <ProgressButton
                  loading={isSubmitting}
                  onClick={() => {
                    handleSubmit(async (data) => {
                      try {
                        if (
                          !data.scheduleDateTime ||
                          data.scheduleDateTime <= new Date().getTime()
                        ) {
                          throw Error("Schedule date time must be in future");
                        }
                        const result = await savePost({
                          ...data,
                          status: "SCHEDULED",
                          notify: false
                        });
                        toast.success(`Post sucessfully scheduled`);
                        setShowSchedulePublish(false);
                        navigate(`/posts/${result.id}`, {
                          replace: true
                        });
                      } catch (error) {
                        const msg = parseErrorResponse(error);
                        toast.error(msg);
                      }
                    })();
                  }}
                >
                  Schedule post
                </ProgressButton>
              </div>
            </>
          );
        }}
      </Modal>
    </>
  );
}

export default PostEdit;
