import { ChangeEvent, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Author, Gender } from "../common/models";
import {
  parseErrorResponse,
  setEmptyOrString,
  setStringToSlug
} from "../common/utils";
import { Input, Select, Textarea } from "../components/forms";
import ProgressButton from "../components/ProgressButton";
import { saveAuthor } from "./AuthorAPI";

function AuthorEdit({ value }: { value: Author }) {
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);

  const {
    control,
    register,
    formState: { isSubmitting, errors },
    setValue,
    handleSubmit
  } = useForm({ values: value, defaultValues: {} });

  function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
    try {
      const files = event.target.files;

      if (files && files.length > 0) {
        const file = files[0];
        const fileSize = file.size / (1024 * 1024);

        if (fileSize > 0.36) {
          throw Error("File size must not greater than 360KB");
        }

        var reader = new FileReader();
        reader.onload = function (e) {
          const result = e.target?.result;
          if (result && typeof result === "string") {
            setValue("file", file);
            setValue("image", result);
          }
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      const msg = parseErrorResponse(error);
      toast.error(msg);
    } finally {
      event.target.value = "";
    }
  }

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col-lg-6">
          <h3 className="fw-semibold mb-1">Author Edit</h3>
          <nav aria-label="breadcrumb col-12">
            <ol className="breadcrumb mb-1">
              <li className="breadcrumb-item">
                <Link to="/authors" className="link-anchor">
                  Authors
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Author Edit
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-lg-6 hstack">
          <div className="ms-lg-auto"></div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12 col-lg-8 order-2 order-lg-1">
              <div className="row g-3">
                <div className="col-lg-6">
                  <Input
                    label="Name"
                    {...register("name", {
                      required: "Please enter author name",
                      setValueAs: setEmptyOrString,
                      onChange: (evt) => {
                        setValue("slug", setStringToSlug(evt.target.value), {
                          shouldValidate: !!errors.slug?.message
                        });
                      }
                    })}
                  />
                </div>
                <div className="col-lg-6">
                  <Controller
                    control={control}
                    name="slug"
                    rules={{
                      validate: (v) => {
                        if (!setStringToSlug(v)) {
                          return "Please enter valid slug";
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <Input
                            label="Slug"
                            value={field.value ?? ""}
                            onChange={(evt) => {
                              const s = evt.target.value
                                .replace(/[^\w-\s]*/, "")
                                .replace(/\s+/, "-")
                                .toLowerCase();

                              setValue("slug", s, {
                                shouldValidate: true
                              });
                            }}
                            error={error?.message}
                          />
                          {!error?.message && (
                            <small className="text-muted">{`${
                              process.env.REACT_APP_CONSUMER_URL
                            }/authors/${field.value ?? ""}`}</small>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
                <div className="col-12">
                  <Select
                    label="Gender"
                    {...register("gender", {
                      setValueAs: (v) => (!v ? undefined : (v as Gender))
                    })}
                  >
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                  </Select>
                </div>
                <div className="col-12">
                  <Select label="Role" {...register("role")}>
                    <option value={"Chief Editor"}>Chief Editor</option>
                    <option value={"Editor"}>Editor</option>
                    <option value={"Assistant Editor"}>Assistant Editor</option>
                    <option value={"Chief Reporter"}>Chief Reporter</option>
                    <option value={"Senior Reporter"}>Senior Reporter</option>
                    <option value={"Reporter"}>Reporter</option>
                    <option value={"Assistant Reporter"}>
                      Assistant Reporter
                    </option>
                    <option value={"Author"}>Author</option>
                  </Select>
                </div>
                <div className="col-12">
                  <Textarea label="Biography" {...register("biography")} />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 order-1 order-lg-2">
              <h5 className="mb-3 text-center">Profile image</h5>
              <div className="hstack justify-content-center">
                <div className="position-relative rounded-circle overflow-hidden">
                  <div className="ratio ratio-1x1" style={{ width: 150 }}>
                    <Controller
                      control={control}
                      name="image"
                      render={({ field }) => {
                        return (
                          <img
                            src={field.value ?? "/images/placeholder-jpeg"}
                            alt="Author"
                            className="rounded-circle border"
                            style={{
                              objectFit: "cover"
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div
                    role={"button"}
                    className="position-absolute bottom-0 start-0 end-0 py-2 bg-dark bg-opacity-50 text-light text-center"
                    onClick={() => fileRef.current?.click()}
                  >
                    Edit
                  </div>
                </div>
              </div>

              <input
                ref={fileRef}
                type="file"
                className="d-none"
                accept="image/x-png,image/jpeg"
                onChange={handleImageChange}
              />
            </div>
          </div>
        </div>
        <div className="card-footer border-top-0 py-3">
          <ProgressButton
            loading={isSubmitting}
            onClick={() => {
              handleSubmit(async (data) => {
                try {
                  var result = await saveAuthor(data);
                  toast.success(
                    `Author successfully ${!data.id ? "created" : "updated"}`
                  );
                  navigate(`/authors/${result.id}`, {
                    replace: true
                  });
                } catch (error) {
                  const msg = parseErrorResponse(error);
                  toast.error(msg);
                }
              })();
            }}
          >
            {!value.id ? "Create" : "Update"}
          </ProgressButton>
        </div>
      </div>
    </>
  );
}

export default AuthorEdit;
