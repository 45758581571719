import { ReactNode } from "react";

interface AlertProps {
  message: ReactNode;
  varaint?: "primary" | "info" | "warning" | "danger" | "default";
  className?: string;
}

function Alert(props: AlertProps) {
  const { message, varaint = "primary", className } = props;

  return (
    <div className={`alert alert-${varaint} ${className ?? ""}`} role="alert">
      {message}
    </div>
  );
}

export default Alert;
