import makeApiRequest from "../common/makeApiRequest";
import { User } from "../common/models";
import { validateResponse } from "../common/utils";

export async function login(body: { email: string; password: string }) {
  const resp = await makeApiRequest("v1/auth/sign-in", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });

  await validateResponse(resp);

  return resp.json().then((json) => json["loginUser"] as User);
}

export async function signOut() {
  const resp = await makeApiRequest(
    "v1/auth/sign-out",
    {
      method: "POST",
      credentials: "include"
    },
    true
  );

  await validateResponse(resp);
}

export async function getLoginUser() {
  const resp = await makeApiRequest("v1/auth/me", {}, true);

  await validateResponse(resp);

  return resp.json() as Promise<User>;
}

export async function changePassword(
  currentPassword: string,
  newPassword: string
) {
  const url = `v1/auth/change-password`;
  const body = {
    currentPassword: currentPassword,
    newPassword: newPassword
  };

  const resp = await makeApiRequest(
    url,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    },
    true
  );

  await validateResponse(resp);
}
