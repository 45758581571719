import ReactSelect from "react-select";
import { Tag } from "../common/models";
import useSWR from "swr";
import { findTags } from "../tag/TagAPI";
import { formControlHeight } from "../common/app.config";

interface TagSelectProps {
  value?: Tag[];
  onChange?: (values: Tag[]) => void;
  error?: string;
}

function TagSelect(props: TagSelectProps) {
  const { value, onChange } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, error, isLoading } = useSWR("/tags", findTags, {
    revalidateOnFocus: false
  });

  return (
    <ReactSelect
      name="tags"
      styles={{
        control: (css, state) => ({
          ...css,
          padding: "0 0.25rem",
          minHeight: formControlHeight,
          boxShadow: "none"
        })
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#dddddd",
          primary: "#4f46e5"
        }
      })}
      value={value}
      isMulti={true}
      isLoading={isLoading}
      options={data}
      menuPlacement="auto"
      getOptionValue={(tag) => `${tag.id ?? ""}`}
      getOptionLabel={(tag) => tag.name ?? ""}
      onChange={(values) => {
        onChange?.([...values]);
      }}
    />
  );
}

export default TagSelect;
