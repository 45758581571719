import makeApiRequest from "../common/makeApiRequest";
import { PageData, User } from "../common/models";
import { buildQueryParams, validateResponse } from "../common/utils";

export async function createUser(user: User) {
  const url = `v1/users`;

  const resp = await makeApiRequest(
    url,
    {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json"
      }
    },
    true
  );

  await validateResponse(resp);

  return resp
    .json()
    .then((json) => json as User)
    .catch((e) => undefined);
}

export async function updateUser(user: User) {
  const url = `v1/users`;

  const resp = await makeApiRequest(
    url,
    {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json"
      }
    },
    true
  );

  await validateResponse(resp);

  return resp
    .json()
    .then((json) => json as User)
    .catch((e) => undefined);
}

export async function updatePassword(userId: number, password: string) {
  const url = `v1/users/${userId}/password`;
  const body = {
    password: password
  };

  const resp = await makeApiRequest(
    url,
    {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    },
    true
  );

  await validateResponse(resp);
}

export async function findUsers(page?: number) {
  const params = buildQueryParams({
    page: page
  });

  const url = `v1/users${params}`;

  const resp = await makeApiRequest(url, {}, true);

  await validateResponse(resp);

  return (await resp.json()) as PageData<User>;
}
