import { redirect, RouteObject } from "react-router-dom";
import { getLoginUser } from "../authentication/AuthenticationAPI";
import ChangePassword from "../authentication/ChangePassword";
import Login from "../authentication/Login";
import AuthorCreate from "../author/AuthorCreate";
import AuthorList from "../author/AuthorList";
import AuthorUpdate from "../author/AuthorUpdate";
import Template from "../components/Template";
import Dashboard from "../dashboard/Dashboard";
import ErrorPage from "../ErrorPage";
import PostCreate from "../post/PostCreate";
import PostList from "../post/PostList";
import PostUpdate from "../post/PostUpdate";
import TagList from "../tag/TagList";
import UserList from "../user/UserList";

export const routes = [
  {
    path: "/",
    element: <Template />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        path: "/change-password",
        element: <ChangePassword />
      },
      {
        path: "/tags",
        element: <TagList />
      },
      {
        path: "/authors",
        children: [
          {
            index: true,
            element: <AuthorList />
          },
          {
            path: "new",
            element: <AuthorCreate />
          },
          {
            path: ":id",
            element: <AuthorUpdate />
          }
        ]
      },
      {
        path: "/posts",
        children: [
          {
            index: true,
            element: <PostList />
          },
          {
            path: "new",
            element: <PostCreate />
          },
          {
            path: ":id",
            element: <PostUpdate />
          }
        ]
      },
      {
        path: "/users",
        children: [
          {
            index: true,
            element: <UserList />
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    loader: async () => {
      try {
        const user = await getLoginUser();
        if (user) {
          return redirect("/");
        }
      } catch (error) {}

      return null;
    },
    element: <Login />
  }
] as RouteObject[];
