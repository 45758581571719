import {
  ComputerDesktopIcon,
  NewspaperIcon,
  SwatchIcon,
  UserGroupIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface MenuItemProps {
  to: string;
  label: string;
  icon: ReactNode;
  expanded: boolean;
  children?: ReactNode;
}

function MenuItem({ to, label, icon, expanded, children }: MenuItemProps) {
  return (
    <li className={`nav-item mb-2 ${expanded ? "px-lg-2" : ""}`}>
      {expanded ? (
        <NavLink
          to={to}
          className={({ isActive }) => {
            var activeClass = isActive ? "active" : "";
            return "nav-link px-2 custom-nav-link rounded-1 " + activeClass;
          }}
          replace
          end
        >
          <div className="hstack">
            {icon}
            <span className="ms-2">{label}</span>
            {children}
          </div>
        </NavLink>
      ) : (
        <NavLink
          to={to}
          className={({ isActive }) => {
            var activeClass = isActive ? "active" : "";

            return `nav-link px-2 py-3 custom-nav-link ${activeClass}`;
          }}
          replace
          end
        >
          <div className="d-flex justify-content-center">{icon}</div>
        </NavLink>
      )}
    </li>
  );
}

function SideMenu({ expanded = true }) {
  return (
    <div className="d-flex flex-column flex-shrink-0 h-100 text-light">
      <div
        className={`hstack text-white ${
          expanded ? "px-3" : "justify-content-center"
        }`}
        style={{
          minHeight: 70
        }}
      >
        <img src="/logo512.png" width={40} height={40} alt="" />
        {/* <ShoppingBagIcon width={32} className="text-primary" /> */}
        {expanded && <h4 className="mb-0 ms-3 fw-bold">Admin</h4>}
      </div>
      <hr className="text-muted my-0"></hr>

      <div
        className="scrollbar-none"
        style={{
          overflowY: "auto"
        }}
      >
        <ul
          className={`nav flex-column text-center ${
            expanded ? "text-lg-start" : ""
          } mb-auto pt-2`}
        >
          <MenuItem
            to="/"
            icon={<ComputerDesktopIcon width={!expanded ? 24 : 20} />}
            label="Dashboard"
            expanded={expanded}
          />
          <hr className="text-muted my-0"></hr>
          {expanded && (
            <span className="text-white-50 my-3 small fw-bold px-3 text-uppercase">
              CONTENTS
            </span>
          )}
          <MenuItem
            to="/tags"
            icon={<SwatchIcon width={!expanded ? 24 : 20} />}
            label="Tags"
            expanded={expanded}
          />

          <MenuItem
            to="/authors"
            icon={<UserGroupIcon width={!expanded ? 24 : 20} />}
            label="Authors"
            expanded={expanded}
          />

          <MenuItem
            to="/posts"
            icon={<NewspaperIcon width={!expanded ? 24 : 20} />}
            label="Posts"
            expanded={expanded}
          />

          <hr className="text-muted mt-1"></hr>

          <MenuItem
            to="/users"
            icon={<UsersIcon width={!expanded ? 24 : 20} />}
            label="Users"
            expanded={expanded}
          />
        </ul>
      </div>
      {/* <div
        className={`custom-border-top d-flex align-items-center px-lg-3`}
        style={{ minHeight: 70 }}
      >
      </div> */}
    </div>
  );
}

export default SideMenu;
