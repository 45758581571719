import {
  NewspaperIcon,
  TagIcon,
  UserGroupIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { parseErrorResponse } from "../common/utils";
import Alert from "../components/Alert";
import { getStatistics } from "./DashboardAPI";

const StatisticItem = ({
  icon,
  title,
  value,
  to
}: {
  icon: ReactElement;
  title: string;
  value: number;
  to: string;
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="hstack gap-3">
          {icon}
          <div className="vstack">
            <div className="mb-1 text-muted">{title}</div>
            <h4 className="mb-0 fw-semibold">{value}</h4>
          </div>
        </div>
      </div>
      <div className="card-footer py-2h">
        <Link to={to} className="text-decoration-none">
          View all
        </Link>
      </div>
    </div>
  );
};

function Dashboard() {
  const { data, error } = useSWR("/statistics", getStatistics, {
    revalidateOnFocus: false
  });

  if (error) {
    return <Alert message={parseErrorResponse(error)} varaint="danger" />;
  }

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
      <div className="col">
        <StatisticItem
          icon={
            <NewspaperIcon
              width={44}
              className="text-muted"
              strokeWidth={1.5}
            />
          }
          title="Posts"
          value={data?.totalPost ?? 0}
          to="posts"
        />
      </div>
      <div className="col">
        <StatisticItem
          icon={<TagIcon width={44} className="text-muted" strokeWidth={1.5} />}
          title="Tags"
          value={data?.totalTag ?? 0}
          to="tags"
        />
      </div>
      <div className="col">
        <StatisticItem
          icon={
            <UserGroupIcon
              width={44}
              className="text-muted"
              strokeWidth={1.5}
            />
          }
          title="Authors"
          value={data?.totalAuthor ?? 0}
          to="authors"
        />
      </div>
      <div className="col">
        <StatisticItem
          icon={
            <UsersIcon width={44} className="text-muted" strokeWidth={1.5} />
          }
          title="Users"
          value={data?.totalUser ?? 0}
          to="users"
        />
      </div>
    </div>
  );
}

export default Dashboard;
