import { createContext } from "react";
import { User } from "./models";

export type AuthStatus = "loading" | "success" | "unauthorized" | "failed";

export type UpdateAuthContextFn = (status: AuthStatus, user?: User) => void;

export interface AuthState {
  status: AuthStatus;
  currentUser?: User;
  update?: UpdateAuthContextFn;
}

export const AuthContext = createContext<AuthState>({
  status: "loading"
});
