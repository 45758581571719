import dayjs from "dayjs";
import { APIError, ForbiddenError, UnauthorizeError } from "./customs";

export function formatTimestamp(timestamp: number | string, withTime = false) {
  let date = dayjs(timestamp);
  if (withTime) {
    return date.format("MMM DD, YYYY hh:mm A");
  }

  return date.format("MMM DD, YYYY");
}

export function formatNumber(value: number) {
  if (isNaN(value) || `${value}`.trim().length === 0) {
    return "";
  }

  return Intl.NumberFormat("en-US").format(value);
}

export function wordPerMinute(wordCount: number) {
  const averageWordPerMinute = 200;
  const wpm = (wordCount * 60) / averageWordPerMinute;
  return Math.round(wpm / 60);
}

export function debounce(callback: (...args: any[]) => void, timeout = 2000) {
  if (typeof window === "undefined") {
    return () => {};
  }
  let timer: NodeJS.Timeout;

  return (...args: any[]) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      callback?.apply(null, args);
    }, timeout);
  };
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setEmptyOrString(v: any) {
  if (typeof v === "string") {
    return v.trim().length > 0 ? v.trim() : undefined;
  }

  return undefined;
}

export function setEmptyOrNumber(v: any) {
  if (!!v) {
    const numRegex = "^[0-9]+$";
    return `${v}`.match(numRegex) ? parseInt(v) : undefined;
  }

  return undefined;
}

export function setStringToSlug(v?: string) {
  return v
    ?.trim()
    .replaceAll(/[^\w-\s\u1000-\u109F]*/g, "")
    .trim()
    .replaceAll(/\s+/g, "-")
    .toLowerCase();
}

export function buildQueryParams(params: any) {
  if (typeof params !== "object" || params instanceof Array) {
    return "";
  }

  let query = "";

  for (const p in params) {
    if (params[p] === undefined || params[p] === null) {
      continue;
    }
    const delimiter = query.length > 0 ? "&" : "?";
    query += delimiter + `${p}=${params[p]}`;
  }

  return query;
}

export function getAPIBasePath() {
  return process.env.REACT_APP_API_URL ?? "";
}

// export async function getAuthHeader() {
//   try {
//     const accessToken = (await Auth.currentSession())
//       .getAccessToken()
//       .getJwtToken();
//     return "Bearer " + accessToken;
//   } catch (error) {}
//   return "";
// }

export async function validateResponse(resp: Response) {
  if (resp.status === 401) {
    throw new UnauthorizeError("Unauthorized");
  }
  if (resp.status === 403) {
    throw new ForbiddenError();
  }
  if (!resp.ok) {
    throw new APIError(resp.status, await resp.text());
  }
}

export function parseErrorResponse(error: any) {
  if (error instanceof UnauthorizeError) {
    // Auth.signOut()
    //   .catch(console.error)
    //   .finally(() => {
    //     const href =
    //       process.env.REACT_APP_CONSUMER_URL +
    //       "/login?redirect=http://localhost:3080";
    //     window.location.href = href;
    //   });

    const href = window.location.origin + "/login";
    window.location.href = href;
    return "Unauthorized";
  }

  if (error instanceof ForbiddenError) {
    return "FORBIDDEN: You cannot access to this resource";
  }

  if (error instanceof APIError) {
    return error.message;
  }

  if (error instanceof TypeError) {
    return "Server down";
  }

  if (typeof error === "string" && error.length > 0) {
    return error;
  }

  return error?.message ?? "Something went wrong, please try again";
}

export function getCookieValue(key: string) {
  if (typeof window === "undefined") {
    return undefined;
  }
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("accessToken="))
    ?.split("=")[1];
}
