import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { AuthContext } from "../common/contexts";
import { parseErrorResponse } from "../common/utils";
import Alert from "../components/Alert";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import { findUsers } from "./UserAPI";

function UserList() {
  const authContext = useContext(AuthContext);
  const [page, setPage] = useState<number>();
  const { data, error, isLoading } = useSWR(
    ["/users", page],
    ([url, p]) => findUsers(p),
    {
      revalidateOnFocus: false
    }
  );

  const content = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <Alert message={parseErrorResponse(error)} varaint="danger" />;
    }

    if (!data || data.list.length === 0) {
      return <Alert message={"No users found"} />;
    }

    return (
      <>
        <div className="table-responsive mb-3">
          <table className="table align-middle">
            <thead className="text-nowrap align-middle border-bottom border-dark">
              <tr>
                <th scope="col" style={{ minWidth: 300 }}>
                  NAME
                </th>
                <th scope="col" style={{ minWidth: 250 }}>
                  EMAIL
                </th>
                <th scope="col" style={{ minWidth: 150 }}>
                  ROLE
                </th>
              </tr>
            </thead>
            <tbody>
              {data.list.map((u, i) => {
                return (
                  <tr key={u.id}>
                    <th scope="row" className="py-3">
                      {u.name}
                    </th>
                    <td>{u.email}</td>
                    <td>{u.role}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mb-5">
          <Pagination
            currentPage={data.current_page}
            totalPage={data.total_page}
            onChange={(p) => {
              setPage(p);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col-lg-6">
          <h3 className="mb-0 fw-semibold">Users</h3>
        </div>
        <div className="col-lg-6 hstack">
          {/* {authContext.currentUser?.role === "OWNER" && (
            <Link to="new" className="btn btn-primary ms-lg-auto">
              Add new
            </Link>
          )} */}
        </div>
      </div>
      {content()}
    </>
  );
}

export default UserList;
