import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import { Author } from "../common/models";
import { parseErrorResponse } from "../common/utils";
import Alert from "../components/Alert";
import ConfirmModal from "../components/ConfirmModal";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import { AuthorQuery, deleteAuthorById, findAuthors } from "./AuthorAPI";

function AuthorList() {
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [author, setAuthor] = useState<Author>();

  const [query, setQuery] = useState<AuthorQuery>({});
  const { data, error, isLoading } = useSWR(
    [`/authors`, query],
    ([url, q]) => findAuthors(q),
    {
      revalidateOnFocus: false
    }
  );

  const content = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <Alert message={parseErrorResponse(error)} varaint="danger" />;
    }

    if (!data || data.list.length === 0) {
      return <Alert message={"No authors found"} />;
    }

    return (
      <>
        <div className="table-responsive mb-3">
          <table className="table align-middle">
            <thead className="text-nowrap align-middle border-bottom border-dark">
              <tr>
                <th scope="col" style={{ minWidth: 300 }}>
                  NAME
                </th>
                <th scope="col" style={{ minWidth: 200 }}>
                  SLUG
                </th>
                <th scope="col" style={{ minWidth: 250 }}>
                  ROLE
                </th>
                <th scope="col" style={{ minWidth: 200 }}>
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {data.list.map((a, i) => {
                return (
                  <tr key={a.id}>
                    <th scope="row" className="py-3">
                      {a.name}
                    </th>
                    <td>{a.slug}</td>
                    <td>{a.role}</td>
                    <td>
                      <div className="hstack gap-2">
                        <Link to={`${a.id}`} className="btn btn-default">
                          <PencilSquareIcon width={20} />
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            setAuthor(a);
                            setShowConfirm(true);
                          }}
                        >
                          <TrashIcon width={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mb-5">
          <Pagination
            currentPage={data.current_page}
            totalPage={data.total_page}
            onChange={(p) => {
              setQuery((old) => {
                return { ...old, page: p };
              });
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col-lg-6">
          <h3 className="mb-0 fw-semibold">Authors</h3>
        </div>
        <div className="col-lg-6 hstack">
          <Link to="new" className="btn btn-primary ms-lg-auto">
            Add new
          </Link>
        </div>
      </div>
      {content()}
      <ConfirmModal
        show={isShowConfirm}
        message={`Are you sure to delete "${author?.name}"?`}
        close={() => setShowConfirm(false)}
        onHidden={() => setAuthor(undefined)}
        onConfirm={async () => {
          try {
            author?.id && (await deleteAuthorById(author.id));
          } catch (error) {
            const msg = parseErrorResponse(error);
            toast.error(msg);
          }
        }}
      />
    </>
  );
}

export default AuthorList;
