import { useMemo } from "react";
import AsyncSelect from "react-select/async";
import { findAuthors } from "../author/AuthorAPI";
import { formControlHeight } from "../common/app.config";
import { Author } from "../common/models";
import { debounce } from "../common/utils";

interface AuthorSelectProps {
  value?: Author;
  error?: string;
  onChange?: (value?: Author) => void;
}

function AuthorSelect(props: AuthorSelectProps) {
  const { value, error, onChange } = props;

  const filterOption = useMemo(() => {
    return debounce(async (resolve, value) => {
      try {
        var data = await findAuthors({ name: value });
        resolve(data.list);
      } catch (error) {
        resolve([]);
      }
    }, 1000);
  }, []);

  const promiseOptions = (inputValue: string) =>
    new Promise<Author[]>(async (resolve, reject) => {
      filterOption(resolve, inputValue);
    });

  return (
    <AsyncSelect
      name="author"
      styles={{
        control: (css, state) => ({
          ...css,
          padding: "0 0.25rem",
          minHeight: formControlHeight,
          boxShadow: "none",
          borderColor: !error ? css.borderColor : "#d50002"
        })
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#E6E5FB",
          primary: "#4f46e5"
        }
      })}
      isClearable
      value={value}
      placeholder="Type here to search..."
      menuPlacement="auto"
      cacheOptions
      defaultOptions={false}
      loadOptions={promiseOptions}
      getOptionValue={(a) => `${a.id ?? 0}`}
      getOptionLabel={(a) => a.name ?? ""}
      onChange={(value) => {
        onChange?.(value ?? undefined);
      }}
    />
  );
}

export default AuthorSelect;
