import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { Tag } from "../common/models";
import { parseErrorResponse } from "../common/utils";
import Alert from "../components/Alert";
import ConfirmModal from "../components/ConfirmModal";
import Loading from "../components/Loading";
import { deleteTagById, findTags } from "./TagAPI";
import TagEdit from "./TagEdit";

function TagList() {
  const [isShowEdit, setShowEdit] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);

  const [tag, setTag] = useState<Tag>();

  const { data, error, isLoading } = useSWR("/tags", findTags, {
    revalidateOnFocus: false
  });

  const content = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <Alert message={parseErrorResponse(error)} varaint="danger" />;
    }

    if (!data || data.length === 0) {
      return <Alert message={"No tags found"} />;
    }

    return (
      <>
        <div className="table-responsive">
          <table className="table align-middle">
            <thead className="text-nowrap align-middle border-bottom border-dark">
              <tr>
                <th scope="col" style={{ minWidth: 300 }}>
                  NAME
                </th>
                <th scope="col" style={{ minWidth: 200 }}>
                  SLUG
                </th>
                <th scope="col" style={{ minWidth: 200, width: 200 }}>
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((t, i) => {
                return (
                  <tr key={t.id}>
                    <th scope="row" className="py-3">
                      {t.name}
                    </th>
                    <td>{t.slug}</td>
                    <td>
                      <div className="hstack gap-2">
                        <button
                          className="btn btn-default hstack gap-1"
                          onClick={() => {
                            setTag(t);
                            setShowEdit(true);
                          }}
                        >
                          <PencilSquareIcon width={18} />
                        </button>
                        <button
                          className="btn btn-danger hstack gap-2"
                          onClick={() => {
                            setTag(t);
                            setShowConfirm(true);
                          }}
                        >
                          <TrashIcon width={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col-lg-6">
          <h3 className="mb-0 fw-semibold">Tags</h3>
        </div>
        <div className="col-lg-6 hstack">
          <button
            className="btn btn-primary ms-lg-auto"
            onClick={() => {
              setTag({});
              setShowEdit(true);
            }}
          >
            Add new
          </button>
        </div>
      </div>
      {content()}

      <ConfirmModal
        show={isShowConfirm}
        message={`Are you sure to delete "${tag?.name}"?`}
        close={() => setShowConfirm(false)}
        onHidden={() => setTag(undefined)}
        onConfirm={async () => {
          try {
            tag?.id && (await deleteTagById(tag.id));
          } catch (error) {
            const msg = parseErrorResponse(error);
            toast.error(msg);
          }
        }}
      />
      <TagEdit
        value={tag}
        show={isShowEdit}
        close={() => setShowEdit(false)}
        onHidden={() => setTag(undefined)}
      />
    </>
  );
}

export default TagList;
