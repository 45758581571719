import makeApiRequest from "../common/makeApiRequest";
import { Tag } from "../common/models";
import { validateResponse } from "../common/utils";

export async function saveTag(value: Tag) {
  const url = "v1/admin/tags";
  const resp = await makeApiRequest(
    url,
    {
      method: "POST",
      body: JSON.stringify(value),
      headers: {
        "Content-Type": "application/json"
      }
    },
    true
  );

  await validateResponse(resp);
}

export async function deleteTagById(tagId: number) {
  const url = `v1/admin/tags/${tagId}`;

  const resp = await makeApiRequest(
    url,
    {
      method: "DELETE"
    },
    true
  );

  await validateResponse(resp);
}

export async function findTags() {
  const resp = await makeApiRequest("v2/tags");

  await validateResponse(resp);

  return resp.json() as Promise<Tag[]>;
}
