import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import { Lang, Post, PostType, Status } from "../common/models";
import { formatTimestamp, parseErrorResponse } from "../common/utils";
import Alert from "../components/Alert";
import ConfirmModal from "../components/ConfirmModal";
import { Select } from "../components/forms";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import { deletePostById, findPosts, PostQuery } from "./PostAPI";

function PostList() {
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [post, setPost] = useState<Post>();

  const [query, setQuery] = useState<PostQuery>({});
  const { data, error, isLoading, mutate } = useSWR(
    [`/posts`, query],
    ([url, q]) => findPosts(q),
    {
      revalidateOnFocus: false
    }
  );

  const statusView = (status?: Status) => {
    if (status === "PUBLISHED") {
      return (
        <div
          className={`small px-2 py-1 rounded text-success border border-success`}
        >
          {status}
        </div>
      );
    }
    if (status === "SCHEDULED") {
      return (
        <div
          className={`small px-2 py-1 rounded text-warning border border-warning`}
        >
          {status}
        </div>
      );
    }

    return (
      <div className={`small px-2 py-1 rounded text-muted border`}>
        {status}
      </div>
    );
  };

  const publishedAtView = (post: Post) => {
    if (post.status === "PUBLISHED") {
      return formatTimestamp(post.publishedAt ?? post.created_at ?? 0, true);
    }

    if (post.status === "SCHEDULED") {
      return post.scheduleDateTime
        ? formatTimestamp(post.scheduleDateTime, true)
        : "";
    }

    return "";
  };

  const content = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <Alert message={parseErrorResponse(error)} varaint="danger" />;
    }

    if (!data || data.list.length === 0) {
      return <Alert message={"No posts found"} />;
    }

    return (
      <div className="vstack gap-3 flex-grow-1">
        <div className="table-responsive">
          <table className="table align-middle">
            <thead className="text-nowrap align-middle border-bottom border-dark">
              <tr>
                <th scope="col" style={{ minWidth: 300 }}>
                  TITLE
                </th>
                <th scope="col" style={{ minWidth: 150 }}>
                  Type
                </th>
                <th scope="col" style={{ minWidth: 150 }}>
                  STATUS
                </th>
                <th scope="col" style={{ minWidth: 250 }}>
                  PUBLISHED AT
                </th>
                <th scope="col" style={{ minWidth: 160 }}>
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {data.list.map((p, i) => {
                return (
                  <tr key={p.id}>
                    <th scope="row" className="py-3">
                      <div className="me-lg-5">{p.title}</div>
                    </th>
                    <td>
                      <span>{p.type}</span>
                    </td>
                    <td>
                      <div className="d-flex">{statusView(p.status)}</div>
                    </td>
                    <td>
                      <div className="text-nowrap">{publishedAtView(p)}</div>
                    </td>
                    <td>
                      <div className="hstack gap-2">
                        <Link to={`${p.id}`} className="btn btn-default">
                          <PencilSquareIcon width={20} />
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            setPost(p);
                            setShowConfirm(true);
                          }}
                        >
                          <TrashIcon width={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-wrap gap-3 align-items-center mb-5">
          <div className="text-muted me-auto">{`Page ${
            data.current_page + 1
          } of ${data.total_page}`}</div>

          <Pagination
            currentPage={data.current_page}
            totalPage={data.total_page}
            onChange={(p) => {
              setQuery((old) => {
                return { ...old, page: p };
              });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-lg-6">
          <h3 className="mb-0 fw-semibold">Posts</h3>
        </div>
        <div className="col-lg-6 hstack">
          <Link to="new" className="btn btn-primary ms-lg-auto">
            Add new
          </Link>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-12 col-lg-auto">
          <Select
            height={44}
            value={query.type ?? ""}
            onChange={(evt) => {
              const v = evt.target.value;
              setQuery((old) => {
                return { ...old, type: !v ? undefined : (v as PostType) };
              });
            }}
          >
            <option value="">By type</option>
            <option value="NEWS">News</option>
            <option value="ARTICLE">Article</option>
            <option value="VIDEO">Video</option>
            <option value="PODCAST">Podcast</option>
            <option value="SURVEY">Survey</option>
            <option value="LAW">Law</option>
          </Select>
        </div>
        <div className="col-12 col-lg-auto">
          <Select
            height={44}
            value={query.lang ?? ""}
            onChange={(evt) => {
              const v = evt.target.value;
              setQuery((old) => {
                return { ...old, lang: !v ? undefined : (v as Lang) };
              });
            }}
          >
            <option value="">By lang</option>
            <option value="MM">Myanmar</option>
            <option value="EN">English</option>
          </Select>
        </div>
        <div className="col-12 col-lg-auto">
          <Select
            height={44}
            value={query.status ?? ""}
            onChange={(evt) => {
              const v = evt.target.value;
              setQuery((old) => {
                return { ...old, status: !v ? undefined : (v as Status) };
              });
            }}
          >
            <option value="">By status</option>
            <option value="PUBLISHED">Published</option>
            <option value="SCHEDULED">Scheduled</option>
          </Select>
        </div>
      </div>
      {content()}

      <ConfirmModal
        show={isShowConfirm}
        message={`Are you sure to delete "${post?.title}"?`}
        close={() => setShowConfirm(false)}
        onHidden={() => setPost(undefined)}
        onConfirm={async () => {
          try {
            post?.id && (await deletePostById(post.id));
            toast.success("Post deleted successfully");
            mutate();
          } catch (error) {
            const msg = parseErrorResponse(error);
            toast.error(msg);
          }
        }}
      />
    </>
  );
}

export default PostList;
