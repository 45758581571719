import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { parseErrorResponse } from "../common/utils";
import Alert from "../components/Alert";
import { Input, PasswordInput } from "../components/forms";
import ProgressButton from "../components/ProgressButton";
import { login } from "./AuthenticationAPI";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      email: "",
      password: ""
    }
  });

  return (
    <div className="vstack h-100">
      <div className="vstack justify-content-center mb-8 px-3 flex-grow-1">
        <div
          className="ratio ratio-1x1 align-self-center mb-3 shadow rounded-circle"
          style={{ width: 100 }}
        >
          <img src="/logo512.png" alt="cover" />
        </div>
        <div
          className="card align-self-center"
          style={{
            maxWidth: 480
          }}
        >
          <div className="card-body p-md-4">
            <h4 className="fw-semibold mb-4">Sign In</h4>
            {error && (
              <Alert message={error} varaint="danger" className="mb-3" />
            )}
            <form
              className="row g-3"
              onSubmit={(evt) => {
                evt.preventDefault();
                handleSubmit(async (data) => {
                  try {
                    await login(data);
                    navigate("/", {
                      replace: true
                    });
                  } catch (error) {
                    const msg = parseErrorResponse(error);
                    setError(msg);
                  }
                })();
              }}
            >
              <div className="col-12">
                <Input
                  label="Email"
                  type="email"
                  autoComplete="username"
                  placeholder="name@domain.com"
                  {...register("email", {
                    required: "Please enter email address"
                  })}
                  error={errors.email?.message}
                />
              </div>
              <div className="col-12">
                <PasswordInput
                  label="Password"
                  autoComplete="current-password"
                  placeholder="Enter password"
                  {...register("password", {
                    required: "Please enter password"
                  })}
                  error={errors.password?.message}
                />
              </div>
              <div className="col-12">
                <ProgressButton
                  type="submit"
                  loading={isSubmitting}
                  className="w-100 mt-3 py-2h"
                >
                  Login
                </ProgressButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
