import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { parseErrorResponse } from "../common/utils";
import { PasswordInput } from "../components/forms";
import ProgressButton from "../components/ProgressButton";
import { changePassword } from "./AuthenticationAPI";

export default function ChangePassword() {
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    }
  });

  return (
    <div className="row">
      <div className="col-lg-7">
        <div className="card">
          <div className="card-header py-3 bg-white">
            <h4 className="mb-0">Change Password</h4>
          </div>
          <div className="card-body">
            <div className="row g-3 py-2">
              <div className="col-12">
                <PasswordInput
                  label="Current Password *"
                  {...register("currentPassword", {
                    required: "Please enter current password"
                  })}
                  error={errors.currentPassword?.message}
                />
              </div>
              <div className="col-12 col-lg-6">
                <PasswordInput
                  label="New Password *"
                  {...register("newPassword", {
                    required: "Please enter new password"
                  })}
                  error={errors.newPassword?.message}
                />
              </div>
              <div className="col-12 col-lg-6">
                <PasswordInput
                  label="Confirm Password *"
                  {...register("confirmPassword", {
                    required: "Please enter confirm password",
                    validate: (v, fv) => {
                      if (v !== fv.newPassword) {
                        return "Password does not match";
                      }
                      return true;
                    }
                  })}
                  error={errors.confirmPassword?.message}
                />
              </div>
            </div>
          </div>
          <div className="card-footer py-3 border-top-0">
            <div className="hstack">
              <ProgressButton
                loading={isSubmitting}
                className="ms-auto"
                onClick={() => {
                  handleSubmit(async (data) => {
                    try {
                      await changePassword(
                        data.currentPassword,
                        data.newPassword
                      );
                      reset({});
                      toast.success("Change password success");
                    } catch (error) {
                      const msg = parseErrorResponse(error);
                      toast.error(msg);
                    }
                  })();
                }}
              >
                Save
              </ProgressButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
