import makeApiRequest from "../common/makeApiRequest";
import { Statistic } from "../common/models";
import { validateResponse } from "../common/utils";

export async function getStatistics() {
  const resp = await makeApiRequest("v1/admin/dashboard/statistic", {}, true);
  await validateResponse(resp);

  return resp.json() as Promise<Statistic>;
}
