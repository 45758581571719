import { Bars3Icon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signOut } from "../authentication/AuthenticationAPI";
import { AuthContext } from "../common/contexts";
import { parseErrorResponse } from "../common/utils";
import Dropdown from "../components/Dropdown";

function Header({ onBarClick }: { onBarClick: () => void }) {
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();

  const user = authContext.currentUser;

  return (
    <nav
      className="navbar sticky-top navbar-expand navbar-light bg-white border-bottom"
      style={{ height: 70 }}
    >
      <div className="container-fluid px-3">
        <div
          role="button"
          className="navbar-brand d-none d-lg-block"
          onClick={() => onBarClick?.()}
        >
          <Bars3Icon width={24} />
        </div>

        <ul className="navbar-nav ms-auto">
          {user && (
            <Dropdown
              toggle={
                <div className="hstack gap-2">
                  <UserCircleIcon width={24} />
                  {user.name}
                </div>
              }
              className="nav-item"
              toggleClassName="dropdown-toggle hstack"
              menuClassName="dropdown-menu-end"
            >
              <Link to={"/change-password"} className="dropdown-item">
                Change password
              </Link>
              <div className="dropdown-divider"></div>
              <li
                className="dropdown-item"
                role="button"
                onClick={() => {
                  signOut()
                    .then(() => {
                      authContext.update?.("unauthorized", undefined);
                      navigate("/login");
                    })
                    .catch((e) => {
                      toast.error(parseErrorResponse(e));
                    });
                }}
              >
                Sign out
              </li>
            </Dropdown>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Header;
