import { useParams } from "react-router-dom";
import useSWR from "swr";
import { PostForm } from "../common/models";
import { setStringToSlug } from "../common/utils";
import { getPostById } from "./PostAPI";
import PostEdit from "./PostEdit";

function PostUpdate() {
  const { id } = useParams();

  if (!id || isNaN(+id)) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }

  const { data, error, isLoading, isValidating } = useSWR(
    `/posts/${+id}`,
    () => getPostById(+id),
    {
      revalidateOnFocus: false,
      errorRetryCount: 0
    }
  );

  if (isLoading || isValidating) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  const value = {
    ...data,
    slug: setStringToSlug(data.slug),
    author: data.correspondent,
    tags: data.categories
  } as PostForm;

  return <PostEdit post={value} />;
}

export default PostUpdate;
